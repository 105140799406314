#mainNav {
  padding: .8em;
  margin: 0;
  border-radius: 0;
  border:none;
  background: $color_primary;

  #logo-header {
    padding-top: 0;

    img {
      display: block;
      height: 50px;
    }
  }

  li a {
    text-shadow: 0 0 1px $color_primary;
    color: #fff;

    &:hover {
      text-shadow: 0 0 1px #000;
      color: #fff;
      background: $color_primary;
    }
  }
}

@media (max-width: 700px) {
  #mainNav {
    background: $color_primary;

    #logo-header img {
      padding-left: 0;
    }
  }
}
