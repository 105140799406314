.plan {
  float: left;
  min-height: 450px;
  border: 1px solid #d7d7d7;
  width: 100%;
  color: #fff;
  margin-bottom: .6em;

  .title {
    background: $color_primary;
    text-align: center;
    padding: 2em;

    h2 {
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
      font-weight: 100;
      margin: 0;
      text-transform: uppercase;
    }

    h3 {
      margin: 0;
      text-transform: uppercase;
    }
  }

  .detail {
    padding: .8em;

    ul {
      padding: 0;
      margin: 0;
    }

    li {
      color: $color_primary;
      border-bottom: 1px solid #d7d7d7;
      padding: .5em;
    }
  }
}