.portfolio-box {
  min-height: 280px;
  //border:1px solid red;
  text-align: center;
  position: relative;
  @include mobile {
    border-bottom: 1px solid rgb(212, 212, 212);
    background-color: white;
    box-shadow: 0 0 2px grey;
    border-radius: 2px;
    margin-bottom: 15px;

    h3 {
      margin: 6px 0;
      font-size: 1.7rem;
      text-transform: uppercase;
    }
  }

  img {
    position: absolute;
    display: block;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    @include mobile{
      width: 95%;
    }
  }
}