.card {
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 6px;

  > .card-link {
    color: #333;

    &:hover {
      text-decoration: none;
    }

    .card-img img {
      border-radius: 6px 6px 0 0;
    }
  }

  .card-img {
    position: relative;
    padding: 0;
    display: table;

    .card-caption {
      position: absolute;
      right: 0;
      bottom: 16px;
      left: 0;
    }
  }

  .card-body {
    display: table;
    width: 100%;
    padding: 12px;
  }

  .card-header {
    border-radius: 6px 6px 0 0;
    padding: 8px;
  }

  .card-footer {
    border-radius: 0 0 6px 6px;
    padding: 8px;
  }

  .card-left {
    position: relative;
    float: left;
    padding: 0 0 8px 0;
  }

  .card-right {
    position: relative;
    float: left;
    padding: 8px 0 0 0;
  }

  .card-body {
    h1:first-child, h2:first-child, h3:first-child, h4:first-child, .h1, .h2, .h3, .h4 {
      margin-top: 0;
    }

    .heading {
      display: block;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .lead {
      text-align: center;
    }
  }
}

@media (min-width: 768px) {
  .card {
    .card-left {
      float: left;
      padding: 0 8px 0 0;
    }

    .card-right {
      float: left;
      padding: 0 0 0 8px;
    }

    .card-4-8 {
      .card-left {
        width: 33.33333333%;
      }

      .card-right {
        width: 66.66666667%;
      }
    }

    .card-5-7 {
      .card-left {
        width: 41.66666667%;
      }

      .card-right {
        width: 58.33333333%;
      }
    }

    .card-6-6 {
      .card-left, .card-right {
        width: 50%;
      }
    }

    .card-7-5 {
      .card-left {
        width: 58.33333333%;
      }

      .card-right {
        width: 41.66666667%;
      }
    }

    .card-8-4 {
      .card-left {
        width: 66.66666667%;
      }

      .card-right {
        width: 33.33333333%;
      }
    }
  }
}

/* -- default theme ------ */

.card-default {
  border-color: #ddd;
  background-color: #fff;
  margin-bottom: 24px;

  > {
    .card-header, .card-footer {
      color: #333;
      background-color: #ddd;
    }

    .card-header {
      border-bottom: 1px solid #ddd;
      padding: 8px;
    }

    .card-footer {
      border-top: 1px solid #ddd;
      padding: 8px;
    }

    .card-body {
    }

    .card-img:first-child img {
      border-radius: 6px 6px 0 0;
    }

    .card-left {
      padding-right: 4px;
    }

    .card-right {
      padding-left: 4px;
    }
  }

  p:last-child {
    margin-bottom: 0;
  }

  .card-caption {
    color: #fff;
    text-align: center;
    text-transform: uppercase;
  }
}

/* -- price theme ------ */

.card-price {
  border-color: #999;
  background-color: #ededed;
  margin-bottom: 24px;

  > {
    .card-heading, .card-footer {
      color: #333;
      background-color: #fdfdfd;
    }

    .card-heading {
      border-bottom: 1px solid #ddd;
      padding: 8px;
    }

    .card-footer {
      border-top: 1px solid #ddd;
      padding: 8px;
    }

    .card-img:first-child img {
      border-radius: 6px 6px 0 0;
    }

    .card-left {
      padding-right: 4px;
    }

    .card-right {
      padding-left: 4px;
    }
  }

  .card-caption {
    color: #fff;
    text-align: center;
    text-transform: uppercase;
  }

  p:last-child {
    margin-bottom: 0;
  }

  .price {
    text-align: center;
    color: #337ab7;
    font-size: 3em;
    text-transform: uppercase;
    line-height: 0.7em;
    margin: 24px 0 16px;

    small {
      font-size: 0.4em;
      color: #66a5da;
    }
  }

  .details {
    list-style: none;
    margin-bottom: 24px;
    padding: 0 18px;

    li {
      text-align: center;
      margin-bottom: 8px;
    }
  }

  .buy-now {
    text-transform: uppercase;
  }

  table {
    .price {
      font-size: 1.2em;
      font-weight: 700;
      text-align: left;
    }

    .note {
      color: #666;
      font-size: 0.8em;
    }
  }
}