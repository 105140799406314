.admin-zone {
  padding-top: 70px;

  h3 {
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: 800;
  }

  section {
    padding-top: 0 !important;
  }

  .btn {
    clear: both;
    display: inline-block;
  }
}