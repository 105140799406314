footer {
  padding: 30px 0;
  color: #f5f5f5;
  background: $color_primary;
  font-weight: 100;

  @include mobile {
    text-align: center;
  }

  a, li {
    font-weight: 500;
    font-size: 12px !important;
  }

  .subtitle {
    color: white;
    font-weight: 600;
    letter-spacing: 1px;
    font-size: 16px;
    padding-top: 10px;
    margin-bottom: 20px;
  }

  #last-footer, #mapa-sitio {
    margin: 0;
  }

  #last-footer {
    height: 168px;

    img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      @include mobile {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    li, ul {
      list-style: none;
      padding: 0;
    }
  }

  #mapa-sitio {
    li, ul {
      list-style: none;
      padding: 0;
    }
  }

  #last-footer li, #mapa-sitio li {
    border-bottom: 1px solid #202930;
    padding: .2em 0;
  }

  #last-footer a, #mapa-sitio a {
    color: #fff;
    text-transform: uppercase;
    font-size: .8em;
    margin-bottom: .3em;
    display: block;
  }
}