#detalle {
  h3, h4 {
    display: block;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 5px;
    padding: 0;
  }

  .slide-detail {
    img {
      display: block;
      margin: 0 auto;
      max-height: 500px;
      width: auto !important;
      max-width: 93%;
    }
  }
}